.about-page {
  height: 100vh;
  width: 100vw;
  padding-top: 8rem;
}

.about-page-bg {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(../images/about_page_bg.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}

.about-content-container {
  max-width: 500px;
  margin: auto;
  padding: 0 2rem;
}

.about-page-image {
  height: 100px;
}
