.page {
  padding-top: 7rem;
  margin-bottom: 7rem;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 90%;
  margin: auto;
}

@media (max-width: 1000px) {
  .gallery-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .gallery-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.gallery-image {
  max-width: 400px;
  width: 26vw;
  margin: auto;
}

@media (max-width: 1000px) {
  .gallery-image {
    width: 44vw;
  }
}

@media (max-width: 600px) {
  .gallery-image {
    width: 80vw;
  }
}
