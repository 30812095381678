.app-header {
  width: 100vw;
  display: flex;
  position: fixed;
}

.header-logo {
  height: 40px;
}

.nav-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transform: translateX(-100%);
  transition: transform 0.2s;
}

.nav-link-image {
  height: 26px;
}

.bg-white {
  background-color: rgba(255, 255, 255, 0.8);
}

.show-nav-links {
  transform: translateX(0);
}

.d-fit {
  display: block;
}
